/* istanbul ignore file: todo check after controller is using this file */
import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams, IStylesParams, StylesParamKeys} from '../../baseStylesParams';
import {GridType, HoverType, LoadMoreType} from '../../types/galleryTypes';
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';

export const DEFAULT_WISHLIST_PRODUCTS_COUNT = 12;
export const DEFAULT_WISHLIST_MANUAL_ROWS = 3;
export const DEFAULT_WISHLIST_MANUAL_COLS = 3;

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => {
  return {
    ...baseCustomCssVars(params),
  };
};

const stylesParams: IStylesParams = {
  ...baseStylesParams,
  responsive: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showAddToCartButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showQuickView: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_hoverType: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: HoverType.None,
    }),
  },
  galleryColumns: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_WISHLIST_MANUAL_COLS,
  },
  galleryRows: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_WISHLIST_MANUAL_ROWS,
  },
  gallery_gapSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  gallery_gapSizeRow: {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  gallery_gapSizeColumn: {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  gallery_loadMoreProductsType: {
    type: StyleParamType.Number,
    getDefaultValue: () => LoadMoreType.PAGINATION,
  },
  gallery_productSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 200,
  },
  gallery_productsCount: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_WISHLIST_PRODUCTS_COUNT,
  },
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: () => GridType.MANUAL,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
